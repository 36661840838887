import { Accordion,Card } from "react-bootstrap";
import Bg from '../../assets/bellecolombia/noticias/fondo_noticias.jpg';

const App=()=>{
  return  <>
            <div className="container-fluid p-0" >
              <img src={Bg} className="img-noticias" />
            </div>
          </>
}

export default App